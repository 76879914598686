import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import envData from "../../../../config/env.json";
import { isSignedInSelector } from "../../../redux/selectors";
import callGA from "../../../analytics/call-ga";

const { apiLocation, homeLocation } = envData;

const mapStateToProps = createSelector(isSignedInSelector, (isSignedIn) => ({
  isSignedIn,
}));

interface LoginProps {
  block?: boolean;
  children?: React.ReactNode;
  "data-test-label"?: string;
  isSignedIn?: boolean;
}

const GetStarted = ({
  block,
  children,
  "data-test-label": dataTestLabel,
  isSignedIn,
}: LoginProps): JSX.Element => {
  const { t } = useTranslation();
  const href = isSignedIn
    ? `${homeLocation}/settings`
    : `${apiLocation}/signin`;

  return (
    <>
      <a
        className="get-started-button-new"
        data-test-label={dataTestLabel}
        href={href}
        onClick={() => {
          callGA({ event: "sign_in" });
        }}
      >
        <span>
          {isSignedIn ? "Claim your certifications" : "Get started for free"}
        </span>
      </a>
      <style>
        {` 
    .get-started-button-new{

          padding: 11px 30px;
          gap: 10px;
          border-radius: 10px ;
          opacity: 0px;
          background: #432B84;
          color:white;
          margin-buttom:15px
          font-size:16px;
          text-decoration:none;
         
    }
    .get-started-button-new:hover{

          padding: 11px 30px;
          gap: 10px;
          border-radius: 10px ;
          opacity: 0px;
          background: #432B84;
          color:white;
          margin-buttom:15px
           text-decoration:none;
    }

    .get-started-button-new span{
    font-size:16px;
    }
    
    
  @media (max-width: 768px) {
   .get-started-button-new{

          padding: 11px 30px;
          gap: 10px;
          border-radius: 10px ;
          opacity: 0px;
          background: #432B84;
          color:white;
          margin-buttom:15px
          font-size:12px;
          text-decoration:none;
           display:flex;
          align-items:center;
          justify-content:center
    }
    .get-started-button-new:hover{

          padding: 11px 30px;
          gap: 10px;
          border-radius: 10px ;
          opacity: 0px;
          background: #432B84;
          color:white;
          margin-buttom:15px
           text-decoration:none;
    }
    .get-started-button-new span{
    font-size:12px;  
    }
  
  }
    `}
      </style>
    </>
  );
};

GetStarted.displayName = "GetStarted";

export default connect(mapStateToProps)(GetStarted);
