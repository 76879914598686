import React from 'react';
import { useTranslation } from 'react-i18next';
import GetStarted from '../../Header/components/get-started';

const BigCallToAction = ({ text }: { text?: string }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
      <GetStarted
      style={{width:"100%"}}
      block={true}
      data-test-label='landing-big-cta'
      data-playwright-test-label='landing-big-cta'
    >
      {text ? text : t('buttons.logged-in-cta-btn')}
    </GetStarted>
    </div>
  );
};

BigCallToAction.displayName = 'BigCallToAction';
export default BigCallToAction;
